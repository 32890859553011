import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import { sortProductsAdvanced } from 'utils/helpers';

import TopBanner from 'components/TopBanner';
import ProductCardsList from 'components/ProductCardsList';
import WhereToBuy from 'components/WhereToBuy';
import Testimonials from 'components/Testimonials';
import FaqBlock from 'components/FaqBlock';
import BodyRenderer from 'components/common/BodyRenderer';
import CategoriesNav from 'components/common/CategoriesNav';
import BreadCrumbs from 'components/common/BreadCrumbs';
import SevenSymptomsBanner from 'components/symptomsCampaign/SevenSymptomsBanner';

import PLP_TEMPLATE_CONSTANTS from './constants';
import { TProductPageComponentProps } from './models';
import './ProductListingPage.scss';
import './ProductListingPageOverride.scss';

const elements = {
  'Where to buy': ({ properties }, keyId) => <WhereToBuy key={keyId} {...properties} />,
  'Testimonials Carousel': ({ properties }, keyId) => <Testimonials key={keyId} {...properties} />,
  'FAQ block': ({ properties }, keyId) => <FaqBlock key={keyId} {...properties} />,
};

const ProductPage: FC<TProductPageComponentProps> = ({
  data: {
    page: {
      pageName,
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      openGraphImageUrl,
      limit,
      limitMobile,
      more,
      filterTitle,
      filterSubtitle,
      subCategories,
      masterTag,
      ariaMore,
      linkCompare,
      showCompareLink,
      topBanner: [{ properties: topBannerData }],
      body,
      linkQuiz,
      hideVariant,
      isFamily,
      showBreadcrumbs,
      sevenSymptomsBanner,
      faqBlock,
      cardTheme,
      sortProductsBy,
      productCardTitleTag,
    },
    relatedProducts,
    siteSettings,
    commonSettings,
    header,
    footer,
  },
  pageContext: { productsLinks, breadCrumbs },
}) => {
  const formattedRelatedProducts = {
    nodes: sortProductsAdvanced(
      relatedProducts.nodes.map((item) => ({ ...item, hideVariant })),
      sortProductsBy
    ),
  };

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        openGraphImageUrl,
      }}
      siteSettings={siteSettings}
      commonSettings={commonSettings}
      header={header}
      footer={footer}
    >
      <BreadCrumbs data={breadCrumbs} showBreadcrumbs={showBreadcrumbs} />
      <TopBanner
        {...topBannerData}
        imageStrategy={PLP_TEMPLATE_CONSTANTS.topBannerProps.imageStrategy}
        imageStyles={PLP_TEMPLATE_CONSTANTS.topBannerProps.imageStyles}
        classes="product-top-banner"
      />
      {subCategories ? (
        <CategoriesNav
          selectedTag={masterTag}
          categories={subCategories}
          filterTitle={filterTitle}
          filterSubtitle={filterSubtitle}
        />
      ) : null}
      <ProductCardsList
        {...{
          relatedProducts: formattedRelatedProducts,
          isProductsSorted: sortProductsBy?.[0] === 'order',
          limit,
          limitMobile,
          more,
          ariaMore,
          linkCompare,
          showCompareLink,
          linkQuiz,
          productsLinks,
          isFamily,
          cardTheme,
          listingName: pageName,
          productCardTitleTag,
        }}
      />
      <BodyRenderer bodyData={body} bodyStructure={elements} />
      {sevenSymptomsBanner?.length ? <SevenSymptomsBanner data={sevenSymptomsBanner} /> : null}
      {faqBlock?.length ? <FaqBlock {...faqBlock[0].properties} /> : null}
    </Layout>
  );
};

export const query = graphql`
  query ProductListingQuery(
    $productsLinks: [String]
    $link: String
    $lang: String
    $productImageDesktopSize: Int
    $productImageMobileSize: Int
    $productImageMobileQuality: Int
    $productImageDesktopQuality: Int
  ) {
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    commonSettings(lang: { eq: $lang }) {
      blocks {
        structure
        properties {
          ...FragmentDefaultCommonSettingsProps
        }
      }
    }
    header(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footer(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    page: productListing(link: { eq: $link }, lang: { eq: $lang }) {
      pageName
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        key
        value
      }
      openGraphImageUrl
      id
      limit
      limitMobile
      showCompareLink
      linkCompare {
        url
        name
      }
      more
      ariaMore
      productsLinks
      hideVariant
      filterTitle
      filterSubtitle
      subCategories {
        name
        link {
          name
          url
        }
      }
      masterTag {
        name
        link {
          name
          url
        }
      }
      isFamily
      showBreadcrumbs
      cardTheme
      sortProductsBy
      topBanner {
        properties {
          description
          image {
            ...FragmentGatsbyImage
          }
          imageAlt
          mobileImage {
            ...FragmentGatsbyProps
            gatsbyImage {
              ...FragmentSmallImage
            }
            mobileImage {
              childImageSharp {
                fluid(maxWidth: 768, quality: 80) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          mobileImageAlt
          title {
            value
          }
          bannerTheme
        }
      }
      linkQuiz {
        properties {
          ariaLabel
          image {
            ...FragmentGatsbyImage
          }
          imageAlt
          title
          link {
            name
            url
          }
        }
      }
      sevenSymptomsBanner {
        ...FragmentSevenSymptomsBanner
      }
      faqBlock {
        properties {
          title
          color
          selectedIndex
          isExpanded
          items {
            properties {
              answer
              question
            }
          }
        }
      }
      productCardTitleTag
      body {
        ... on TTestimonialsCarouselStructure {
          structure
          properties {
            color
            title
            slides {
              properties {
                imageAlt
                image {
                  ...FragmentGatsbyImage
                }
                description
                ariaLink
                link {
                  name
                  url
                }
              }
            }
          }
        }
        ... on TWheretobuyStructure {
          structure
          properties {
            color
            title
            listMode
            limit
            limitMobile
            cards {
              properties {
                imageAlt
                type
                title
                link {
                  url
                  target
                  queryString
                }
                image {
                  ...FragmentGatsbyImage
                }
              }
            }
          }
        }
      }
    }
    relatedProducts: allProduct(filter: { link: { in: $productsLinks } }) {
      nodes {
        sku
        link
        productImage {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentSmallProductImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: $productImageMobileSize, quality: $productImageMobileQuality) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        linkFamily {
          url
        }
        imageFamily {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentSmallImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 210) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        productImageAlt
        cardTitle
        familyCardTitle
        disclaimer
        tickItems {
          value
        }
        tags {
          isProductFamily
          color {
            label
          }
          name
        }
        featureItems {
          properties {
            icon
            label
          }
        }
        cartFakeLink
        currentFormat {
          properties {
            label
          }
        }
        flavours {
          properties {
            label
          }
        }
        sizes {
          properties {
            label
          }
        }
        order
      }
    }
  }
`;

export default ProductPage;
